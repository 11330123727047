import React from "react";
import "../css/footer.css";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <>
      <div className="bottom-container">
        <div className="innerBotBox">
          <div className="innerTopBox">
            <div className="botLogo">
              <img alt="" src="/bungayImg/head1.jpg" />
            </div>
            <div className="botLeft">
              <div className="comImfo">
                <h3 className="comImfoH3">Contact</h3>
                <p>
                  Fen Farm Dairy
                  <br />
                  Flixton Road, Bungay, Suffolk, NR35 1PD
                </p>
                <div className="contactAA">
                  <div>
                    <Link to="#">supportzq@fenfarmdairy.online</Link>
                  </div>
                  <div>
                    <Link to="#">01986 892 350</Link>
                  </div>
                </div>
              </div>
              <div className="comImfo">
                <ul>
                  <h3 className="comImfoH3">Navigation</h3>
                  <li>
                    <Link to="/">SHOP</Link>
                  </li>
                  <li>
                    <Link to="/contact">CONTACT</Link>
                  </li>

                  <li>
                    <Link to="/aboutUs">ABOUT</Link>
                  </li>
                </ul>
              </div>
              <div className="comImfo">
                <h3 className="comImfoH3">Social</h3>
                <div className="findSvg">
                  <h6>Find Us On:</h6>
                  <div className="toBox">
                    <Link to="#">
                      <img alt="" src="/bungayImg/pic.svg" />
                    </Link>
                    <Link to="#">
                      <img alt="" src="/bungayImg/face.svg" />
                    </Link>
                    <Link to="#">
                      <img alt="" src="/bungayImg/twitter.svg" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="legal">
            <div className="legalLeftBox">
              <p>Home</p>
              <p>Contact</p>
            </div>
            <div className="footLeftBot">
              <div className="legalLeft">© Copyright Fen Farm Dairy 2024</div>
              <div className="footer_bar_seperator">l</div>
              <div className="webdesign_by_perfect">
                Website by <Link to="https://www.perfect.uk">Affinity</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
